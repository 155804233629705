import { render, staticRenderFns } from "./HeaderTag.vue?vue&type=template&id=07637f14&scoped=true&"
import script from "./HeaderTag.vue?vue&type=script&lang=js&"
export * from "./HeaderTag.vue?vue&type=script&lang=js&"
import style0 from "./HeaderTag.vue?vue&type=style&index=0&id=07637f14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07637f14",
  null
  
)

export default component.exports